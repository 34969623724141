.request-history {
    &__heading {
        margin-bottom: 42px;
        font-size: 25px;
        font-weight: 600;
        text-align: center;

        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    &__wrapper {
        margin: 0 auto;
        max-width: 883px;
        width: 100%;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 34px 66px;
        width: 100%;
        background: var(--white-color);
        border-radius: 27px;
        box-shadow: 0px 0px 37px rgba(114, 114, 114, 0.15);

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            padding: 37px 32px;
        }
        @media screen and (max-width: 767px) {
            padding: 27px 22px;
        }
    }
    &__item + &__item {
        margin-top: 43px;

        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }
    &__block + &__block {
        margin-left: 46px;

        @media screen and (max-width: 1023px) {
            display: none;
            margin-top: 20px;
            margin-left: 0;
        }
    }
    &__inner {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 500;
        color: #283039;

        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    &__title + &__data,
    &__data + &__title {
        margin-left: 24px;

        @media screen and (max-width: 767px) {
            margin-top: 6px;
            margin-left: 0;
        }
    }
    &__data {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #283039;
    }
    &__description {
        margin-top: 18px;
        font-size: 17px;
        font-weight: normal;
        line-height: 1.32;
        color: #3A434A;

        @media screen and (max-width: 767px) {
            margin-top: 13px;
            font-size: 16px;
        }
    }
    &__value {
        display: block;
        font-size: 17px;
        font-weight: normal;
        line-height: 1.32;
        white-space: nowrap;
        color: #3A434A;
        b, strong {
            font-weight: bold;
        }
    }
    &__value + &__value {
        margin-top: 5px;
    }
    &__more {
        display: block;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        margin: 72px auto 0;
        padding-bottom: 3px;
        width: fit-content;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.12;
        color: #081F31;
        border-bottom: 1px solid #081F31;
        background: none !important;
        &:hover {
            color: #081F31;
        }

        @media screen and (hover: hover) {
            &:hover {
                color: #3E81F7;
                border-color: #3E81F7;
            }
        }
        @media screen and (max-width: 767px) {
            margin: 50px auto 0;
        }
    }
}