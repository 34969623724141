.home {
    padding-top: 214px;
    padding-bottom: 40px;

    @media screen and (max-width: 767px) {
        padding-top: 30px;
    }

    &__container {
        margin: 0 auto;
        padding: 0 62px;
        max-width: 1243px;
        width: 100%;

        @media screen and (max-width: 767px) {
            padding: 0 28px;
        }
    }
}