.characteristics-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    margin-right: -22px;
    margin-bottom: -22px;
    &__item {
        display: flex;
        align-items: center;
        margin-right: 22px;
        margin-bottom: 22px;
        padding: 13px 14px;
        font-size: 15px;
        font-weight: normal;
        text-align: center;
        background: #F5F7F8;
        border-radius: 17px;
    }
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-left: 10px;
        padding: 0;
        min-width: 10px;
        width: 10px;
        height: 10px;
        background: transparent;
        border: none;
        border-radius: 0;
        &:focus {
            outline: none;
        }
        
        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
    }
    &__close-icon {
        min-width: 10px;
        width: 10px;
        height: 10px;
        color: #283039;
    }
}