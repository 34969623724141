.auth {
    background: #FFFFFF;
    &__container {
        margin: 0 auto;
        max-width: 432px;
        width: 100%;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 600px;
        }
    }
    &__wrapper {
        display: flex;
        min-height: 100vh;
    }
    &__left {
        display: flex;
        flex-direction: column;
        padding: 57px 59px;
        width: 42%;

        @media screen and (min-width: 1024px) and (max-width: 1099px) {
            width: 46%;
        }
        @media screen and (max-width: 1023px) {
            padding: 63px 40px;
            width: 100%;
        }
    }
    &__heading {
        margin-bottom: 92px;
        font-size: 30px;
        font-weight: 500;
        color: #081F31;

        @media screen and (max-width: 767px) {
            margin-bottom: 52px;
            font-size: 24px;
            font-weight: 600;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 auto;
        padding-top: 90px;
        
        @media screen and (max-width: 767px) {
            padding-top: 61px;
        }
    }
    &__input + &__input {
        margin-top: 32px;

        @media screen and (max-width: 767px) {
            margin-top: 34px;
        }
    }
    &__error {
        display: none;
        margin-top: 34px;
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        color: #E86756;
        &.is--active {
            display: block;
        }
    }
    &__btn {
        margin: 34px auto 0;
        max-width: 354px;
        width: 100%;
        
        @media screen and (max-width: 767px) {
            margin: 50px auto 0;
        }
    }
    &__message + * {
        margin-top: 82px;

        @media screen and (max-width: 767px) {
            margin-top: 87px;
        }
    }
    &__message-icon {
        display: block;
        overflow: hidden;
        margin: 0 auto;
        max-width: 97px;
        min-width: 97px;
        height: 97px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__message-icon + &__message-title,
    &__message-title + &__message-icon {
        margin-top: 18px;
    }
    &__message-title {
        display: block;
        font-size: 22px;
        font-weight: 500;
        text-align: center;

        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    &__description {
        display: block;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.1;
        color: #000000;
        b, strong {
            font-weight: bold;
        }
    }
    &__description + * {
        margin-top: 76px;

        @media screen and (max-width: 767px) {
            margin-top: 37px;
        }
    }
    &__help {
        display: block;
        margin: 45px auto 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        text-align: center;
        & > a {
            color: #3E81F7;
            border-bottom: 1px solid #3E81F7;
            &:hover {
                color: #3E81F7;
            }
        }

        @media screen and (max-width: 767px) {
            margin: 36px auto 0;
            font-size: 18px;
        }
    }
    &__help + &__help {
        margin-top: 24px;

        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
    &__right {
        position: relative;
        z-index: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: calc(50% - 50vw);
        width: 58%;

        @media screen and (min-width: 1024px) and (max-width: 1099px) {
            width: 54%;
        }
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    &__decor {
        &--first {
            position: absolute;
            right: -195px;
            bottom: -259px;
            z-index: -1;
            transform: translate3d(0, 0, 0);
            display: block;
            width: 338px;
            height: 499px;
            background-image: url(../../assets/images/auth-bg-01.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            filter: blur(90px);
        }
        &--second {
            position: absolute;
            right: 608px;
            bottom: -214px;
            z-index: -1;
            transform: translate3d(0, 0, 0);
            display: block;
            width: 444px;
            height: 374px;
            background-image: url(../../assets/images/auth-bg-02.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            filter: blur(90px);
        }
        &--third {
            position: absolute;
            top: -683.5px;
            left: 5%;
            z-index: -1;
            transform: translate3d(-50%, 0, 0);
            display: block;
            width: 903px;
            height: 1079px;
            background-image: url(../../assets/images/auth-bg-03.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            filter: blur(90px);
        }
        &--fourth {
            position: absolute;
            top: -610px;
            left: 39%;
            z-index: -2;
            transform: translate3d(-50%, 0, 0);
            display: block;
            width: 1178px;
            height: 1318px;
            background-image: url(../../assets/images/auth-bg-04.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            filter: blur(90px);
        }
    }
    &__promo {
        display: block;
        max-width: 373px;
        width: 100%;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.34;
        text-align: center;
        color: #081F31;

        @media screen and (min-width: 1024px) {
            &--mobile {
                display: none;
            }
        }
        @media screen and (max-width: 1023px) {
            margin-top: 10px;
            max-width: 260px;
            font-size: 16px;
            text-align: left;
            color: #89919A;
        }
    }
    &__promo + &__img {
        margin-top: 90px;
    }
    &__img {
        display: block;
        overflow: hidden;
        max-width: 363px;
        min-width: 363px;
        width: 100%;
        height: auto;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}