.top-button {
    position: fixed;
    left: 95px;
    bottom: 27px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: 69px;
    width: 69px;
    height: 69px;
    font-size: 0;
    background: var(--white-color);
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px);
    transition: all .15s ease-in-out;

    @media screen and (hover: hover) {
        &:hover {
            & > svg {
                color: #3E81F7;
            }
        }
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        left: 45px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        left: 25px;
    }
    @media screen and (max-width: 767px) {
        left: 20px;
        min-width: 50px;
        width: 50px;
        height: 50px;
    }

    &.show_button {
        transform: translateY(0);
        opacity: 1;
    }

    &__icon {
        min-width: 25px;
        width: 25px;
        height: 25px;
        color: #283039;

        @media screen and (max-width: 767px) {
            min-width: 20px;
            width: 20px;
            height: 20px;
        }
    }
}