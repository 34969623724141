.header {
    position: fixed;
    top: 39px;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    padding: 20.5px 62px;
    max-width: 1243px;
    width: 100%;
    background: var(--white-color);
    border-radius: 57px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.06);
    
    @media screen and (max-width: 767px) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin-bottom: 135px;
        padding: 20px 32px;
        background: transparent;
        box-shadow: none;
    }
    
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logotype {
        display: block;
        overflow: hidden;
        max-width: 116px;
        min-width: 116px;
        height: 17px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__nav {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &__dropdown {
        position: absolute;
        right: 12px;
        bottom: 0;
        left: 12px;
        z-index: 100;
        transform: translateY(85%);
        display: block;
        padding: 19px 20px;
        max-width: calc(100% - 24px);
        width: 100%;
        visibility: hidden;
        opacity: 0;
        background: var(--white-color);
        border-radius: 57px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.06);
        pointer-events: none;
        transition: all 0.25s ease-in-out;
        .nav {
            &__list {
                justify-content: space-around;
                width: 100%;
            }
        }
        &.is--active {
            transform: translateY(100%);
            visibility: visible;
            opacity: 1;
            pointer-events: all;
        }
    }
}

.nav {
    &__list {
        display: flex;
        align-items: center;
    }
    &__item + &__item {
        margin-left: 50px;
    }
    &__link {
        display: inline-block;
        font-size: 18px;
        font-weight: normal;
        line-height: 1;
        color: #283039;
        border: none;
        background: none;
        padding: 0;
        &:hover {
            color: #283039;
        }

        @media screen and (hover: hover) {
            &:hover {
                color: #3E81F7;
            }
        }
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.hamburger {
    position: relative;
    display: block;
    padding: 0;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    border-radius: 0;
    &:focus {
        outline: none;
    }
    &.is--active {
        .hamburger {
            &__inner {
                &:first-child {
                    top: 10px;
                    transform: rotate(-45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    top: 10px;
                    transform: rotate(45deg);
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
    
    &__inner {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 3.5px;
        background: #081F31;
        border-radius: 3px;
        transition: all 0.25s ease-in-out;
        &:first-child {
            top: 1px;
        }
        &:nth-child(2) {
            top: 10px;
        }
        &:last-child {
            top: 19px;
        }
    }
}