.keywords {
    padding: 20px 22px 4px;
    background: #F5F7F8;
    border-radius: 8px;
    &--indent {
        margin: 8px 14px 0;
        padding: 20px 24px;
    }
    &__list {
        max-height: 350px;
        overflow: auto;
    }
    &__item {
        cursor: pointer;
    }
    &__item + &__item {
        margin-top: 10px;
    }
    &__elem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        width: 100%;
        text-align: left;
        background: var(--bg, #E0E0E0);
        border: 1px solid var(--border, #C6C6C6);
        border-radius: 4px;
        &:focus {
            outline: none;
        }
    }
    &__text + &__text {
        margin-left: auto;
    }
    &__text {
        display: block;
        font-size: 15px;
        font-weight: normal;
        line-height: 1.6;
        color: #4A4A4A;
        &:not(:last-child) {
            margin-right: 6px;
        }
    }
    &__delete {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -4px;
        min-width: 14px;
        width: 14px;
        height: 14px;
        background: transparent;
        border: none;
        border-radius: 0;
        &:focus {
            outline: none;
        }

        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
    }
    &__delete-icon {
        min-width: 8px;
        width: 8px;
        height: 8px;
        color: #4A4A4A;
    }
}