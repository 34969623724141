.generation {
    &__wrap {
        display: flex;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }
    &__left {
        position: relative;
        padding: 36px 0;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 100vh;
        max-height: 100vh;
        width: 32%;
        background: var(--white-color);

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            width: 35%;
        }
        @media screen and (max-width: 1023px) {
            display: none;
            width: 100%;
            &.is--active {
                display: block;
                padding-bottom: 120px;
            }
        }
    }
    &__container-left {
        margin: 0 auto;
        padding: 0 32px;
        max-width: 420px;
        width: 100%;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 600px;
        }
    }
    &__wrapper-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__top-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 34px;
    }
    &__body-left {
        flex: 1 1 auto;
    }
    &__input {
        .input-field,
        .textarea-field {
            &__label {
                font-size: 20px;
            }
            &__input {
                padding: 18px 22px;
                font-size: 18px;
                background: #F5F7F8;
                border-color: rgba(#000000, 0.08);
                border-radius: 15px;
                &:focus {
                    border-color: #3E81F7;
                }
            }
        }
        .input-field {
            &__input {
                line-height: 1.1;
            }
        }
    }
    &__input + &__input,
    &__input-block + &__input-block {
        margin-top: 45px;

        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }
    .hidden__input {
        display: none;
    }
    &__btn {
        width: 100%;

        @media screen and (max-width: 767px) {
            margin-top: 28px;
        }
    }
    &__attempts {
        display: block;
        margin-top: 16px;
        font-size: 17px;
        font-weight: 500;
        color: #7C7C7C;
        text-align: center;
    }
    &__right {
        position: relative;
        padding: 46px 0;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 100vh;
        max-height: 100vh;
        width: 68%;


        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            width: 65%;
        }
        @media screen and (max-width: 1023px) {
            display: none;
            width: 100%;
            &.is--active {
                display: block;
                padding-bottom: 120px;
            }
        }
    }
    &__container-right {
        margin: 0 auto;
        padding: 0 82px;
        max-width: 881px;
        width: 100%;

        @media screen and (max-width: 767px) {
            padding: 0 32px;
        }
    }
    &__wrapper-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 92px);
    }
    &__close {
        position: absolute;
        top: 37px;
        right: 33px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 24px;
        width: 24px;
        height: 24px;
        background: transparent;
        border: none;
        border-radius: 0;
        cursor: pointer;
        &:focus {
            outline: none;
        }

        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            top: 27px;
            right: 23px;
        }
        @media screen and (min-width: 1024px) {
            &--mobile {
                display: none;
            }
        }
        @media screen and (max-width: 1023px) {
            position: static;
            &--desktop {
                display: none;
            }
        }
    }
    &__close-icon {
        min-width: 18px;
        width: 18px;
        height: 18px;
        color: #283039;
    }
    &__top-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 72px;

        @media screen and (max-width: 767px) {
            align-items: flex-start;
            margin-bottom: 44px;
        }
    }
    &__back {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        font-weight: 500;
        color: #A0A8B1;
        border: none;
        background: inherit;
        &:hover {
            color: #A0A8B1;
        }

        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
    &__back-icon {
        margin-right: 11px;
        min-width: 11px;
        width: 11px;
        height: 11px;
        color: #A0A8B1;
    }
    &__inner {
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) {
            &--mobile {
                display: none;
            }
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-end;
            &--desktop {
                display: none;
            }
        }
    }
    &__inner + &__inner {
        margin-left: 12px;

        @media screen and (max-width: 767px) {
            margin-top: 12px;
            margin-left: 0;
        }
    }
    &__value {
        display: block;
        font-size: 20px;
        font-weight: normal;
        color: #2B2B2B;
        b, strong {
            font-weight: bold;
        }

        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    &__value + &__value,
    &__value + &__date,
    &__date + &__value {
        margin-left: 30px;

        @media screen and (max-width: 767px) {
            margin-top: 12px;
            margin-left: 0;
        }
    }
    &__date {
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: #283039;
        
        @media screen and (min-width: 768px) {
            &--mobile {
                display: none;
            }
        }
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    &__body-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 auto;
    }
    &__main {
        padding: 48px;
        background: var(--white-color);
        border-radius: 31px;
        box-shadow: 0px 0px 37px rgba(114, 114, 114, 0.15);

        @media screen and (max-width: 767px) {
            padding: 28px 24px;
            border-radius: 27px;
        }
    }
    &__preview {
        text-align: center;
        &.is--hidden {
            display: none;
        }

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    &__img {
        display: block;
        overflow: hidden;
        margin: 0 auto;
        max-width: 227px;
        min-width: 227px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media screen and (max-width: 767px) {
            max-width: 177px;
            min-width: 177px;
        }
    }
    &__img + &__help,
    &__img + &__description {
        margin-top: 47px;

        @media screen and (max-width: 767px) {
            margin-top: 24px;
        }
    }
    &__help {
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: #A0A8B1;

        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    &__result {
        &.is--hidden {
            display: none;
        }

        @media screen and (max-width: 1023px) {
            display: block;
            &.is--hidden {
                display: block;
            }
        }
    }
    &__description {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.2;
        &.is--hidden {
            display: none;
        }

        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    &__save {
        margin-top: 36px;
        margin-left: auto;
        padding: 16px;
        min-width: 224px;
        font-size: 18px;

        @media screen and (max-width: 767px) {
            margin: 43px auto 0;
        }
    }
}