.create-description {
    margin: 0 auto 95px;
    max-width: 580px;
    width: 100%;
    &__input {
        .input-field {
            &__input {
                padding: 26px 32px;
                border-color: rgba(#000000, 0.1);
                &:focus {
                    border-color: #3E81F7;
                    box-shadow: 0px 0px 17px rgba(114, 114, 114, 0.15);
                }

                @media screen and (max-width: 767px) {
                    padding: 21px 30px;
                }
            }
        }
    }
    &__btn {
        margin: 50px auto 0;
        max-width: 283px;
        width: 100%;
        border-radius: 12px;

        @media screen and (max-width: 767px) {
            margin: 30px auto 0;
            padding: 22.5px;
            max-width: 276px;
        }
    }
    .generations_quantity {
        font-size: 18px;
        color: #7C7C7C;
        font-weight: 500;
        margin-top: 1.5rem;
        text-align: center;
    }
}