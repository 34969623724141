.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    opacity: 1;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
    &--active {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        .modal {
            &__close {
                opacity: 1;
            }
            &__wrap {
                opacity: 1;
            }
            &__overlay {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    &__scroll {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
    }
    &__wrap {
        position: relative;
        z-index: 2;
        margin: auto;
        max-width: 664px;
        min-width: 664px;
        width: 100%;
        opacity: 0;
        background: var(--white-color);
        border-radius: 39px;
        box-shadow: 0px 0px 44px rgba(55, 55, 55, 0.24);
        transition: all 0.25s ease-in-out;
        &--sm {
            max-width: 400px;
            min-width: 400px;
            .modal {
                &__body {
                    max-width: none;
                    text-align: center;
                }
                &__btn {
                    margin-top: 40px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }

        @media screen and (max-width: 767px) {
            max-width: none;
            min-width: auto;
            width: calc(100vw - 64px);
            border-radius: 19px;
        }
    }
    &__main {
        display: flex;
        justify-content: space-between;
        padding: 70px 46px 54px;
        
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            padding: 56px 20px 20px;
            text-align: center;
        }
    }
    &__close {
        position: absolute;
        top: 27px;
        right: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        width: 24px;
        height: 24px;
        background: transparent;
        border: none;
        border-radius: 0;
        &:focus {
            outline: none;
        }

        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
        @media screen and (max-width: 767px) {
            top: 21px;
            right: 17px;
        }
    }
    &__close-icon {
        min-width: 18px;
        width: 18px;
        height: 18px;
        color: #7B756B;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 244px;
        width: 100%;
    }
    &__title {
        font-size: 21px;
        font-weight: 600;
        color: #283039;

        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    &__description {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #283039;

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &__btn {
        margin-top: 20px;
        padding: 20px;
        width: 100%;

        @media screen and (max-width: 767px) {
            margin: 30px auto 0;
            padding: 16px;
            min-width: 196px;
            width: fit-content;
            font-size: 16px;
        }
    }
    &__img {
        display: block;
        margin-left: 30px;
        overflow: hidden;
        max-width: 275px;
        min-width: 275px;
        width: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        background: rgba(var(--white-color), 0.25);
        backdrop-filter: blur(2.5px);
        pointer-events: none;
        transition: all 0.25s ease-in-out;
    }
}