.tooltip {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 10;
    display: none;
    &.display-tooltip {
        display: block;
    }
    &--top {
        top: -25px;
        transform: translateY(-100%);
        .tooltip {
            &__wrapper {
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%) rotate(180deg);
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 26px solid transparent;
                    border-right: 26px solid transparent;
                    border-bottom: 22px solid #FDEBE0;
                }
            }
        }
    }
    &--bottom {
        bottom: -25px;
        transform: translateY(100%);
        .tooltip {
            &__wrapper {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 26px solid transparent;
                    border-right: 26px solid transparent;
                    border-bottom: 22px solid #FDEBE0;
                }
            }
        }
    }
    &--active {
        display: block;
    }

    @media screen and (max-width: 767px) {
        right: -18px;
        left: -18px;
    }

    &__wrapper {
        position: relative;
        padding: 16px 24px;
        background: #FDEBE0;
        border-radius: 13px;
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    &__title {
        display: block;
        font-size: 15px;
        font-weight: normal;
    }
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        padding: 0;
        min-width: 20px;
        width: 20px;
        height: 20px;
        font-size: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        &:focus {
            outline: none;
        }

        @media screen and (hover: hover) {
            &:hover {
                & > svg {
                    color: #3E81F7;
                }
            }
        }
    }
    &__close-icon {
        min-width: 14px;
        width: 14px;
        height: 14px;
        color: #283039;
    }
    &__description, &__example {
        display: block;
        font-size: 16px;
        font-weight: 500;
    }
    &__example {
        margin-top: 1rem;
    }
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 22px;
        gap: 16px;
        .btn_wrap {
            flex-grow: 1;
        }
    }
    &__btn {
        max-width: 150px;
        width: 100%;
        padding: 15px;
        font-size: 18px;
        border-radius: 10px;
    }
    &__btn + &__btn {
        margin-left: 12px;
    }
}