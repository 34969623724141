.btn_wrap{
    position: relative;
    margin-top: 38px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px;
    width: fit-content;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    color: var(--white-color);
    background: linear-gradient(303.25deg, #FFC973 -14.73%, #FF8D70 77.89%);
    border: none;
    border-radius: 89px;
    transition: all 0.25s ease-in-out;
    &:focus {
        outline: none;
    }
    &--grey {
        color: #283039;
        background: #D9D9D9;
    }
    &[disabled] {
        opacity: 0.7;
        pointer-events: none;
    }

    @media screen and (hover: hover) {
        &:hover {
            color: var(--white-color);
            background: linear-gradient(303.25deg, #FFC973 0%, #FF8D70 127.89%);
        }
        &--grey {
            &:hover {
                color: #283039;
                background: #EBEAEA;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 20.5px;
        font-size: 19px;
    }
}